import React, { useState, useRef, useEffect } from "react";
import { PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";

const CustomVideo = ({ videoSrc, pref, poster }) => {
  const src = videoSrc || "https://www.w3schools.com/html/mov_bbb.mp4";

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    videoRef.current.load();
  }, [videoSrc]);

  useEffect(() => {
    if (videoRef && videoRef.current) pref.current = videoRef.current;
  }, [pref]);

  const handlePlayClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef?.current?.pause();
      } else {
        videoRef?.current?.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="customVideo">
      <video
        ref={videoRef}
        className="video"
        controls={false}
        autoPlay
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        poster={poster || null}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="btnBox">
        {isPlaying ? (
          <PauseCircleOutlined onClick={handlePlayClick} />
        ) : (
          <PlayCircleOutlined onClick={handlePlayClick} />
        )}
      </div>
    </div>
  );
};

export default CustomVideo;
